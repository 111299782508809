import React, { FunctionComponent } from "react";
import { Link } from "gatsby";
import { Seo } from "../components";

const NotFoundPage: FunctionComponent = () => {
  return (
    <>
      <Seo title="Page not found" />
      <div className="container max-w-4xl mx-auto mt-12 md:mt-32 text-center">
        <div className="font-header text-9xl md:text-[12rem] leading-none text-white">
          404
        </div>
        <div className="ml-1 md:ml-6 tracking-[4px] md:tracking-[12px] uppercase text-blue-to-violet font-bold">
          Page not found
        </div>

        <h1 className="mt-10 text-3xl md:text-5xl">
          Oops! Something went wrong!
        </h1>
        <p className="mt-4 text-lg text-gray-400">
          Sorry, the page you're looking for doesn't exist or has been moved
        </p>

        <Link
          className="mt-10 h-14 px-6 inline-flex items-center justify-center font-semibold leading-14 whitespace-nowrap rounded-lg transition-colors ease-out duration-150 w-full md:w-auto text-white bg-blue-500 hover:bg-blue-700"
          to="/"
        >
          Take me home
        </Link>
      </div>
    </>
  );
};

export default NotFoundPage;
